import React from "react";
import { GatsbyBrowser, Script } from "gatsby";
import {
  FixrCmsPluginOptions,
  PluginOptionsProvider,
} from "./src/fixr/components/PluginOptions";
import { Analytics } from "fixr/components/Analytics";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = (
  { element },
  options
) => {
  return (
    <PluginOptionsProvider value={options as unknown as FixrCmsPluginOptions}>
      <>
        <Analytics />
        {element}
        <Script
          id="ticket-catch-script"
          dangerouslySetInnerHTML={{
            __html: `
              window.fixrLinksWidget = function(api) {
                window.fixrLinksWidgetApi = api;
              };
          `,
          }}
        />
        <Script
          async
          id="links-widget"
          src="https://web-cdn.fixr.co/scripts/fixr-checkout-widget.v1.min.js?callback=fixrLinksWidget&headless&theme=dark"
        />
      </>
    </PluginOptionsProvider>
  );
};
