module.exports = [{
      plugin: require('../plugins/gatsby-fixr-cms-plugin/gatsby-browser.tsx'),
      options: {"plugins":[],"siteId":"site-homelincoln-co-uk","baseUrl":"https://homelincoln.co.uk","name":"Home Lincoln","adminRoute":"/admin","templatePath":"/workspace/src/templates","logo":{"src":"/logo.png","alt":"Home Lincoln","width":250,"height":52}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
