import { useContext } from "react";
import { PluginOptionsContext } from "./PluginOptionsContext";

export function usePluginOptions() {
  const context = useContext(PluginOptionsContext);
  if (context === undefined)
    throw new Error(
      "usePluginOptions must be used inside a SiteConfigProvider"
    );
  return context;
}
