import React, { createContext } from "react";
import { DefaultTheme } from "styled-components";

export type FixrCmsPluginOptions = {
  siteId: string;
  baseUrl: string;
  name: string;
  blogRoute: string;
  adminRoute: string;
  templatePath: string;
  logo?: {
    src: string;
    width: string;
    height: string;
  };
  theme?: DefaultTheme["colors"];
};

export const PluginOptionsContext = createContext<
  FixrCmsPluginOptions | undefined
>(undefined);

export const PluginOptionsProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
} & { value: FixrCmsPluginOptions }) => {
  return (
    <PluginOptionsContext.Provider value={value}>
      {children}
    </PluginOptionsContext.Provider>
  );
};
